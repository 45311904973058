export default {
  'companies.loading': 'Finding doctors..',
  'companies.empty': 'No results, try another search term',
  'companies.failure': `Couldn't load doctors`,
  'companies.search': `Search`,
  'companies.searchInputLabel': `Place or search term`,
  'companies.useGeoLocation': `Find doctors in my area`,

  'questions.loading': `Loading questions`,
  'questions.empty': `No questions found`,
  'questions.failure': `Couldn't load questions`,
  'questions.qrMissing': `QR code is missing`,
  'questions.qrUsed': `QR code was already used`,

  'help.common': `Via "next" you may skip a question. Via "back" you can go to a previous question and see your answer.`,
  'help.Stars': `Use the stars to leave a rating.`,
  'help.YesNo': `If you agree, click the green thumb.`,

  'data.Stars':
    'disagree completely,mostly disagree,so so,mostly agree,agree completely',

  'question.lastCanClose': `You can close the app now.`,
  'question.lastIsSaving': `Your answers are being saved, please have a moment of patience.`,
  'question.lastText': `Thank you for your opinion!`,
  'question.next': `Next`,
  'question.previous': `Back`,
  'question.text.placeholder': `Enter your opinion here`,

  'status.loading': `Loading`,
  'status.reload': `Reload`,

  'error.connectionFailed': `Connection failed`,
  'error.failure': `Something went wrong`,
  'error.notFound': `Not found`,
};
