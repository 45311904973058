import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import de from '../locales/de';
import en from '../locales/en';

export const LocaleContext = React.createContext();

const LocaleProvider = ({ children, defaultLanguage = 'de' }) => {
  const [language, setLanguage] = useState(defaultLanguage);
  const [questionMessages, setQuestionMessages] = useState({});
  return (
    <LocaleContext.Provider
      value={{ language, setLanguage, setQuestionMessages }}
    >
      <IntlProvider
        key={language}
        locale={language}
        messages={{
          ...(language === 'en' ? en : de),
          ...questionMessages,
        }}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
