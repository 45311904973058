// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

/* eslint-disable no-undef */
/* eslint-disable no-template-curly-in-string */

import { Route, Router } from '@redwoodjs/router';

const Routes = () => {
  return (
    <Router>
      <Route path="/" page={LandingPage} name="landing" />
      <Route path="/portal" page={CompaniesPage} name="companies" />
      <Route
        path="/details/{company}"
        page={CompanyDetailsPage}
        name="companyDetails"
      />
      <Route
        path="/q/{questionnaire}/?"
        page={QuestionnairePage}
        name="questionnaire"
      />
      <Route
        path="/results/{questionnaire}/?"
        page={ResultsPage}
        name="results"
      />

      <Route path="/qr" page={QrPage} name="qr" />
      <Route path="/datenschutz" page={DatenschutzPage} name="datenschutz" />
      <Route path="/impressum" page={ImpressumPage} name="impressum" />
      <Route notfound page={NotFoundPage} />
    </Router>
  );
};

export default Routes;
