import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web';
import ReactDOM from 'react-dom';
import LocaleProvider from 'src/components/LocaleProvider';
import FatalErrorPage from 'src/pages/FatalErrorPage';
import Routes from 'src/Routes';
import './index.css';

ReactDOM.render(
  <FatalErrorBoundary page={FatalErrorPage}>
    <RedwoodProvider>
      <LocaleProvider
        defaultLanguage={
          navigator.language.split('-')[0] === 'en' ? 'en' : 'de'
        }
      >
        <Routes />
      </LocaleProvider>
    </RedwoodProvider>
  </FatalErrorBoundary>,
  document.getElementById('redwood-app')
);
