export default {
  'companies.loading': 'Suche Doktoren..',
  'companies.empty':
    'Keine Resultate, versuch es mit einem anderen Suchbegriff.',
  'companies.failure': `Verbindungsfehler`,
  'companies.search': `Starte Suche`,
  'companies.searchInputLabel': `Ort oder Suchbegriff`,
  'companies.useGeoLocation': `Zeig mir Ärzte in meiner Umgebung`,

  'questions.loading': `Fragen werden geladen`,
  'questions.empty': `Keine Fragen gefunden`,
  'questions.failure': `Verbindungsfehler`,
  'questions.qrMissing': `QR Code fehlt`,
  'questions.qrUsed': `QR Code wurde bereits verwendet`,

  'help.common': `Über "weiter" können Sie die Frage überspringen. Über "zurück" können Sie sich die letzten Fragen und Ihre Antwort noch einmal anschauen.`,
  'help.Stars': `Geben Sie über die Sterne eine Bewertung ab.`,
  'help.YesNo': `Klicken Sie auf den grünen Daumen, wenn Sie zustimmen.`,

  'data.Stars':
    'stimme überhaupt nicht zu,stimme kaum zu,es geht so,stimme überwiegend zu,stimme komplett zu',

  'question.lastCanClose': `Sie können die App jetzt schließen.`,
  'question.lastIsSaving': `Ihre Antworten werden gespeichert, bitte haben Sie einen Moment Geduld.`,
  'question.lastText': `Vielen Dank für Ihre Meinung!`,
  'question.next': `Weiter`,
  'question.previous': `Zurück`,
  'question.text.placeholder': `Tippen Sie hier Ihre Meinung ein`,

  'status.loading': `Wird geladen`,
  'status.reload': `Erneut versuchen`,

  'error.connectionFailed': `Verbindungsfehler`,
  'error.failure': `Unbekannter Fehler`,
  'error.notFound': `Nicht gefunden`,
};
